<template>
<div class="full-height">
    <div id="sidebar" class="d-none d-lg-block">
        <ul class="nav flex-column pt-4 sidenav mx-2 pb-2 align-bottom" stylen="position: absolute;bottom: 0;width:100%">
            <li class="nav-item">
                <div class="dropdown">
                    <a class="
                nav-link nav-dash
                bg-transparent
                py-4
                dropdown-toggleb
                d-flex
              " href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">

                        <Avatar class="radius align-middle" :square="true" :size="32" variant="beam" v-if="!user.photo_url" :name="user.user_name" :colors="colors" />
                        <img v-if="user.photo_url" :src="user.photo_url" class="rounded-circle align-middle" style="height: 32px" />

                        <span class="profile_name font-weight-bold px-2 t">{{
                user.user_name
              }}</span>

                        <ph-caret-down class="float-right" :size="20" />

                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <form>
                            <a class="dropdown-item" href="#">
                                <ph-user class="pr-1  align-middle" :size="20" />
                                <span class="align-middle">My Profile</span>
                            </a>
                            <a class="dropdown-item" href="#">
                                <ph-gear-six class="pr-1  align-middle" :size="20" />
                                <span class="align-middle">Settings</span>
                            </a>
                            <router-link class="dropdown-item" :to="'/d/billing'">
                                <ph-wallet class="pr-1  align-middle" :size="20" />

                                <span class="align-middle">Billing</span>
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <div class="px-4">
                                <label class="align-middle text">Theme</label>
                                <select class="form-control">
                                    <option value="Dark">Dark</option>
                                    <option value="Light">Light</option>
                                    <option value="System">System</option>

                                </select>
                            </div>

                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" @click.prevent="logout()">

                                <ph-sign-out class="pr-1  align-middle" :size="20" />
                                <span class="align-middle">Logout</span></a>
                        </form>

                    </div>
                </div>
            </li>
        </ul>
        <ul class="nav flex-column pt-4 sidenav ml-3 mr-2">

            <li class="nav-item" :class="{ active: $route.name === 'overview' }">
                <router-link class="nav-link nav-dash waves-primary pl-0 ml-0 " :class="{ active: $route.name === 'overview' }" :to="'/d/overview/'">

                    <ph-dots-nine :size="20" />
                    <span class="px-2 nd-text">Overview</span>
                </router-link>
            </li>
            <li class="nav-item d-none" :class="{ active: $route.name === 'analytics' }">
                <router-link class="nav-link nav-dash waves-primary" :class="{ active: $route.name === 'analytics' }" :to="'/d/analytics/'">
                    <PhChartLine :size="20" />

                    <span class="px-2 nd-text">Analytics</span>
                </router-link>
            </li>

            <li class="nav-item " :class="{ active: $route.name === 'files' }">
                <router-link class="nav-link nav-dash" :class="{ active: $route.name === 'files' }" :to="'/d/files/'">
                    <ph-folder-simple :size="20" />
                    <span class="px-2 nd-text">Files</span>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'beats' ||
                $route.name === 'newbeat' ||
                $route.name === 'editbeat',
            }" :to="'/d/beats/'">
                    <ph-music-note-simple :size="20" />
                    <span class="px-2 nd-text">Beats</span>
                </router-link>
            </li>
            <li class="nav-item d-none">
                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'products' ||
                $route.name === 'newproduct' ||
                $route.name === 'editproduct',
            }" :to="'/d/products/'">
                    <ph-package :size="20" />
                    <span class="px-2 nd-text">Products</span>
                </router-link>
            </li>

            <li class="nav-item d-none">
                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'playlist' ||
                $route.name === 'vplaylist' ||
                $route.name === 'nplaylist',
            }" :to="'/d/playlist/'">
                    <ph-playlist :size="20" />
                    <span class="px-2 nd-text">Playlist</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'sales' || $route.name === 'salesview',
            }" :to="'/d/sales/'">
                    <ph-wallet :size="20" />
                    <span class=" fnt-18"> </span>
                    <span class="px-2 nd-text">Sales</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'customers' || $route.name === 'customersview',
            }" :to="'/d/customers/'">
                    <ph-users :size="20" />
                    <span class=" fnt-18"> </span>
                    <span class="px-2 nd-text">Customers</span>
                </router-link>
            </li>
            <li class="nav-item">

                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'licences' || $route.name === 'licencesnew'|| $route.name === 'editlicences',
            }" :to="'/d/licences/'">
                    <ph-copyright :size="20" />

                    <span class="px-2 nd-text">Licences</span>
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'store' ,
            }" :to="'/d/store/'">
                    <ph-storefront :size="20" />
                    <span class=" fnt-18"> </span>
                    <span class="px-2 nd-text">Store</span>
                </router-link>
            </li>
            <li class="nav-item d-none">
                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'messages' || $route.name === 'messagesview',
            }" :to="'/d/messages/'">
                    <ph-chats :size="20" />
                    <span class=" fnt-18"> </span>
                    <span class="px-2 nd-text">Messages</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link nav-dash" :class="{
              active:
                $route.name === 'settings' ,
            }" :to="'/d/settings/'">
                    <PhWrench :size="20" />
                    <span class=" fnt-18"> </span>
                    <span class="px-2 nd-text">Settings</span>
                </router-link>
            </li>

        </ul>
        <ul class="nav flex-column pt-4 sidenav mx-2 pb-2" style="position: absolute; bottom: 0">
            <li class="nav-item">
                <a class="nav-link nav-dash" href="#">
                    <img src="../assets/img/logo_text.png" style="height: 28px" />

                </a>
            </li>
        </ul>
    </div>

    <div id="mainarea" class="container-fluidb full-height">
        <!--nav class="navbar navbar-expand-lg top-nav py-0">
            <button class="navbar-toggler d-none" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <i class="ph-list cursor-pointer d-lg-none d-sm-block" style="font-size: 24px"></i>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto pt-2">
                    <li class="nav-item active">
                        <a class="nav-link btn btn-secondary  btn-slim mr-2" href="#">
                            <ph-magnifying-glass :size="20" class="ml-1" />
                            <span class="px-2 text-muted">Search Beats,Files etc...</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link btn btn-secondary fnt-18 btn-slim mr-2" href="#">
                            <ph-chat :size="20" />
                            <small class="badge badge-primary ml-1  noti-badge d-none">8</small>
                        </a>
                    </li>
                    <li class="nav-item">

                        <a class="nav-link btn btn-secondary fnt-18 btn-slim mr-2" href="#">
                            <ph-bell :size="20" />
                            <small class="badge badge-primary ml-1  noti-badge d-none">1</small>
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-linkv btn btn-primary " href="#">
                            <span class="px-2 nd-text align-middle">Create New </span>
                            <ph-caret-down :size="16" />
                        </a>
                    </li>
                </ul>
            </div>
        </nav-->
        <router-view :key="$route.fullPath"></router-view>
    </div>
</div>
</template>

<script>
import Avatar from "vue2-boring-avatars";
import {
    PhDotsNine,
    PhFolderSimple,
    PhPlaylist,
    PhPackage,
    PhCopyright,
    PhStorefront,
    PhCaretDown,
    PhGearSix,
    PhUser,
    PhUsers,
    PhWallet,
    PhSignOut,
    PhMagnifyingGlass,
    PhBell,
    PhChat,
    PhCurrencyCircleDollar,
    PhHandshake,
    PhMusicNoteSimple,
    PhChartLine,
    PhWrench,
    PhChats
} from "phosphor-vue";
export default {
    name: "dashboard",
    components: {
        PhDotsNine,
        PhFolderSimple,
        PhPlaylist,
        PhPackage,
        PhCopyright,
        PhStorefront,
        Avatar,
        PhCaretDown,
        PhGearSix,
        PhUser,
        PhUsers,
        PhWallet,
        PhSignOut,
        PhMagnifyingGlass,
        PhBell,
        PhChat,
        PhCurrencyCircleDollar,
        PhHandshake,
        PhMusicNoteSimple,
        PhChartLine,
        PhWrench,
        PhChats
    },
    data() {
        return {
            user: this.$store.state.user,
            colors: ["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"],
        };
    },

    created() {},
    async mounted() {},
    methods: {
        logout() {
            this.$store.dispatch("signOut");
        },
    },
    computed: {
        getUser() {
            //console.log(this.$store.getters.getUser);
            return this.$store.getters.getUser;
        },
    },
};
</script>
